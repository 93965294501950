<template>
    <div 
        class="flex-grow-1 messages-list px-5 text-body-2" 
        ref="messagesList"
    >
    <v-container>
            <div 
                v-for="(message, index) in messages" 
                class="d-flex flex-column py-3" 
                :key="message.id" 
                style="width: 100%;"
            >
                <message :message="message" :nextMessage="messages[index+1] || null" />
            </div>
            <template v-if="chatInProgress">
                <v-slide-y-transition
                    class="py-0"
                    tag="div"
                    group
                    leave-absolute
                >
                    <div
                        v-for="(message) in filteredStreamingMessages"
                        :key="`content-${message.id}`"
                        class="d-flex flex-column py-3"
                        style="width: 100%;"
                    >
                        <streaming-message :message="message" />
                    </div>
                </v-slide-y-transition>
            </template>
        </v-container>
        </div>
</template>

<script>
import Message from './Message.vue';
import StreamingMessage from './StreamingMessage.vue';
import { mapGetters } from 'vuex';


export default {
    components: { Message, StreamingMessage },
    computed: {
        ...mapGetters({
            messages: 'chatAgent/messages',
            streamingMessages: 'chatAgent/streamingMessages',
            chatInProgress: 'chatAgent/chatInProgress',
        }),

        filteredStreamingMessages() {
            return this.streamingMessages.filter((message, index) => {
                if (message.content && message.content.trim() !== "") {
                    return message;
                }

                // check if it is the last streaming message
                if (index === this.streamingMessages.length - 1) {
                    return message;
                }
            })

        },
    },
    watch: {
        messages() {
            // Scroll to the bottom whenever messages are updated
            this.scrollToBottom();
        },
        streamingMessages() {
            // Scroll to the bottom whenever streaming messages are updated
            this.scrollToBottom();
        },
    },
    mounted() {
        // Scroll to the bottom initially
        this.scrollToBottom();
    },
    methods: {
        scrollToBottom() {
            setTimeout(() => {
                const messagesList = this.$refs.messagesList;
                if (messagesList) {
                    messagesList.scrollTop = messagesList.scrollHeight;
                }
            }, 100);
            
        },
    },
};
</script>

<style lang="scss" scoped>
.messages-list {
    overflow-y: auto;
    height: 100%; /* Ensure it occupies the full height of its container */
}

/* Add animation for enter and leave */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>

<style lang="scss">
.chat-markdown-content {
    font-size: 14px;
    line-height: 1.6;
    color: var(--v-secondary-base);
    overflow-wrap: break-word; // Ensures long words break properly

    // Headers
    h1, h2, h3 {
        margin:0;
        font-weight: bold;
    }
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.75em;
    }
    h3 {
        font-size: 1.5em;
    }

    // Paragraphs
    p {
        margin: 0;
        margin-bottom: 1em;
    }

    // Lists
    ul, ol {
        margin: 1em 0;
        padding-left: 2em;

        li {
            margin: 0.5em 0;
        }
    }

    // Blockquotes
    blockquote {
        margin: 0;
        margin-bottom: 1em;
        padding: 0.5em 1em;
        border-left: 4px solid #ccc;
        font-style: italic;
        color: #555;
    }

    // Code blocks
    pre {
        background-color: #272822;
        color: #f8f8f2;
        padding: 1em;
        border-radius: 4px;
        overflow-x: auto; // Enables horizontal scrolling
        font-family: 'Courier New', monospace;
        white-space: pre-wrap; // Allows wrapping inside the pre tag
        word-break: break-word; // Ensures long words break properly
        margin: 1em 0;
    }
    code {
        padding: 0.2em 0.4em;
        border-radius: 4px;
        font-family: 'Courier New', monospace;
        overflow-x: auto; // Enables horizontal scrolling if needed
        white-space: pre-wrap;
        word-break: break-word;
    }

    // Tables
    table {
        display: block; // Enables scrolling for wide tables
        overflow-x: auto; // Horizontal scrolling if needed
        width: 100%; // Prevents overflowing parent container
        border-collapse: collapse;
        margin: 1em 0;

        th, td {
            border: 1px solid #ccc;
            padding: 8px;
            text-align: left;
            word-wrap: break-word; // Wraps content inside cells
        }

        th {
            background-color: #f2f2f2;
            font-weight: bold;
        }
    }

    // Links
    a {
        color: var(--v-primary-darken1);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    // Images
    img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
    }

    // Horizontal Rule
    hr {
        border: none;
        border-top: 1px solid #ccc;
        margin: 1.5em 0;
    }
}
</style>