<template>
    <div class="d-flex" style="width: 100%">
        <v-avatar color="grey lighten-5" class="mr-2" size="30" >
            <img src="https://cdn.voiceform.com/media/nova-vf-logo.svg" alt="Nova">
        </v-avatar>
        <div class="d-flex flex-column flex-grow-1" style="width: 100%">

            <div v-if="message.content && !hideMessageContent" class="pt-1">
                <vue-markdown class="chat-markdown-content" :source="message.content"></vue-markdown>
            </div>
    
            <div class="pt-2" v-if="message.content=== 'formatted_data_for_visualization' && message.artifact">
                <chart-message-card :message="message"></chart-message-card>
            </div>
    
            <div v-if="toolCalls && toolCalls.length>0" class="d-flex pt-1">
                <v-chip
                    v-for="(toolCall, index) in toolCalls"
                    :key="index"
                    class="mr-2 mb-1"
                    label
                    color="primary lighten-5"
                    text-color="primary darken-1"
                    small
                >
                    <v-icon small left>
                        mdi-tools
                    </v-icon>
                    {{getToolLabel(toolCall.name)}}
                </v-chip>
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import ChartMessageCard from './ChartMessageCard.vue'
export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed:{
        toolCalls(){
            if(this.message.tool_calls){
                return this.message.tool_calls;
            }
            return []
        },
        hideMessageContent(){
            return this.message.content === 'formatted_data_for_visualization';
        }
    },
    methods:{
        getToolLabel(toolName){
            if(toolName === 'summarize_data_tool'){
                return 'Summarizing Data';
            }
            if(['retrieve_data_tool', 'retrieve_data'].includes(toolName)){
                return 'Retrieving Data';
            }

            if(toolName === 'visualize_data_tool'){
                return 'Visualizing Data';
            }
        }
    },
    components: {
        VueMarkdown,
        ChartMessageCard
    },
}
</script>
