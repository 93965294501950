<template>
    <div class="d-flex" style="width: 100%">
        <v-avatar color="grey lighten-5" class="mr-2" size="30" >
            <img src="https://cdn.voiceform.com/media/nova-vf-logo.svg" alt="Nova">
        </v-avatar>
        <div class="d-flex flex-column flex-grow-1">

            <div v-if="message.content && !hideMessageContent" class="pt-1">
                <vue-markdown class="chat-markdown-content" :source="message.content">
                </vue-markdown>
                <span class="rounded-cursor"></span>
            </div>
    
            <div class="pt-2" v-if="message.content=== 'formatted_data_for_visualization' && message.artifact">
                <chart-message-card :message="message"></chart-message-card>
            </div>
    
            <div v-if="toolCalls && toolCalls.length>0" class="d-flex pt-1">
                <div
                    v-for="(toolCall, index) in toolCalls"
                    :key="index"
                    class="mr-2 mb-1 px-2 py-1 shimmer-chip text-caption rounded-sm"
                >
                    <v-icon color="primary darken-1" small left>
                        mdi-tools
                    </v-icon>
                    {{getToolLabel(toolCall.name)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import ChartMessageCard from './ChartMessageCard.vue'
export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed:{
        toolCalls(){
            if(this.message.tool_calls){
                return this.message.tool_calls;
            }
            return []
        },
        hideMessageContent(){
            return this.message.content === 'formatted_data_for_visualization';
        },
    },
    methods:{
        getToolLabel(toolName){
            if(toolName === 'summarize_data_tool'){
                return 'Summarizing Data';
            }
            if(['retrieve_data_tool', 'retrieve_data'].includes(toolName)){
                return 'Retrieving Data';
            }

            if(toolName === 'visualize_data_tool'){
                return 'Visualizing Data';
            }

            if(toolName === 'VisualizationResponse'){
                return 'Deciding on Visualization';
            }

            if(toolName === 'BarChartData'){
                return 'Creating a Bar Chart';
            }

            if(toolName === 'LineChartData'){
                return 'Creating a Line Chart';
            }

            if(toolName === 'ScatterChartData'){
                return 'Creating a Scatter Chart';
            }
            // capitalize and separate words with space
            return toolName.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        }
    },
    components: {
        VueMarkdown,
        ChartMessageCard
    },
}
</script>

<style lang="scss" scoped>
.rounded-cursor {
    display: inline-block;
    width: 16px; /* Adjust size */
    height: 16px;
    background-color: var(--v-primary-darken1); /* Adjust color */
    margin-left: 4px;
    border-radius: 4px; /* Rounded square */
    animation: smoothFade 1.8s infinite ease-in-out;
}

@keyframes smoothFade {
    0%, 100% {
        // opacity: 1; /* Fully visible at the start and end */
        transform: scale(1); /* Normal size */
    }
    50% {
        // opacity: 0; /* Invisible in the middle */
        transform: scale(0.8); /* Slightly smaller */
    }
}
.shimmer-chip {
    position: relative;
    overflow: hidden;
    background: var(--v-primary-lighten5); /* Base background color */
    color: var(--v-primary-darken1); /* Ensure text is readable */
}

/* Shimmer animation */
.shimmer-chip::before {
    content: "";
    position: absolute;
    top: 0;
    left: -200%; /* Start outside the view */
    width: 150%;
    height: 100%;
    opacity: 0.5;
    background: linear-gradient(
        90deg,
        transparent 0%,
        var(--v-primary-lighten3) 50%,
        transparent 100%
    );
    animation: shimmer 2s infinite;
}

/* Keyframes for shimmer animation */
@keyframes shimmer {
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(200%);
    }
}
</style>
