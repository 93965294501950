<template>
    <v-hover>
        <template v-slot="{ hover }">
            <div style="width: 100%; position: relative;">
                <v-fade-transition>
                    <div v-if="hover" class="toolbox">
                        <v-btn color="primary lighten-5" fab elevation="0" x-small @click.stop="downloadImage"><v-icon color="primary darken-1">mdi-download</v-icon></v-btn>
                    </div>
                </v-fade-transition>
                <v-chart ref="chart" :autoresize="true" style="height: 400px; width: 100%;" :option="message.artifact" />
            </div>
        </template>
    </v-hover>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, ScatterChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    ScatterChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

export default {
    name: 'ChartMessageCard',
    provide: {
        [THEME_KEY]: "light"
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    components: {
        VChart
    },
    methods: {
        downloadImage() {
            if (this.$refs.chart) {
                const chart = this.$refs.chart;
                const image = chart.getDataURL({
                    pixelRatio: window.devicePixelRatio || 1,
                });

                let fileName = "chart-image.png";

                if(this.message.artifact?.title?.text){
                    // remove periods from the title

                    fileName = this.message.artifact?.title?.text.replace(/\./g, '') + ".png";
                }

                // Create a temporary link element
                const link = document.createElement("a");
                link.href = image; // Set the image URL as the link's href
                link.download = fileName; // Set the file name for the download
                document.body.appendChild(link); // Append the link to the document
                link.click(); // Trigger the download
                document.body.removeChild(link); // Remove the link from the document
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.toolbox {
    z-index: 1;
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}
.chart-card {
  height: 400px;
}
</style>